import '../css/global.scss';
import '../css/jquery-confirm.min.css'
import '../css/jquery-ui.min.css'
import '../css/jquery-ui.theme.css'

import $ from 'jquery'

import 'bootstrap'

import './jquery-confirm.min.js'
import './jquery-ui'

import './routing'

global.$ = global.jQuery = $
